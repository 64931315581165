<template>
  <div>
    <div class="heading">
      Subscribe
    </div>
    <div class="plans">
      <div class="plan">
        <span class="plan-heading">Monthly Plan</span>
        <span class="plan-price">$3.99 / month</span>
        <span class="plan-total">Total: $3.99</span>
        <form
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          target="_top"
        >
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="2LJCBWTGDL8ZL" />
          <input v-if="auth" type="hidden" name="custom" v-model="broadcast" />
          <button type="submit" class="button wide outline" name="submit">
            Pay Now
          </button>
        </form>
      </div>
      <div class="or">- OR -</div>
      <div class="plan">
        <span class="plan-heading">Annual Plan</span>
        <span class="plan-price">$2.99 / month</span>
        <span class="plan-total">Total: $35.88</span>
        <form
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          target="_top"
        >
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="GDDNCJRJVVALW" />
          <input v-if="auth" type="hidden" name="custom" v-model="broadcast" />
          <button type="submit" class="button wide outline" name="submit">
            Pay Now
          </button>
        </form>
      </div>
    </div>
    <div class="notes">
      <p>Cancel at any moment. No commitment.</p>
    </div>
    <div class="terms">
      <p>
        * By purchasing the subscription you automatically agree to our terms of
        service.
      </p>
      <p>** No refund policy. Exceptions may be made at our discretion.</p>
      <p>
        *** We reserve the right to suspend the account in case of any abuse.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['auth', 'broadcast'])
  }
};
</script>

<style scoped lang="scss">
.plans {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;

  .plan {
    flex-basis: 100%;
    width: 100%;
    background: #f3f3f3;
    border: 1px solid #dcdcdc;
    margin: 8px 0;
    padding: 8px;

    .plan-heading {
      display: block;
      font-size: 24px;
      text-align: center;
      padding: 8px;
    }

    .plan-price {
      display: block;
      font-size: 36px;
      font-weight: 400;
      text-align: center;
      padding: 8px;
    }

    .plan-total {
      display: block;
      text-align: center;
      padding: 0 8px 16px 8px;
    }
  }

  .or {
    align-self: center;
    margin: 8px 0;
  }
}

.notes {
  text-align: center;
}

.terms {
  font-size: 12px;
  line-height: 24px;

  p {
    margin: 0;
  }
}

@media only screen and (min-width: 768px) {
  .plans {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 32px;

    .plan {
      flex-basis: 300px;
      width: 300px;
    }
  }
}
</style>
